import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: null,
    user_guid: null,
    email: '',
    display_name: '',
    tech_id: null,
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
